// Imports
import Vue from "vue";
import Router from "vue-router";
import store from "@/store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "",
      redirect: () => {
        return { path: "/home" };
      }
    },
    {
      path: "/admin",
      name: "",
      redirect: () => {
        return { path: "/admin/admin-bills" };
      }
    },

    {
      path: "/login",
      component: () => import("../layouts/page/Index"),
      children: [
        {
          path: "",
          name: "Login",
          component: () => import("../views/Login")
        }
      ]
    },

    {
      path: "/licenseExpired",
      component: () => import("../layouts/page/Index"),
      children: [
        {
          path: "",
          name: "licenseExpired",
          component: () => import("../views/licenseExpired")
        }
      ]
    },

    {
      path: "/admin",
      component: () => import("../layouts/default/Index Admin"),
      meta: {
        access: ["SuperUser", "Admin"]
      },
      children: [
        {
          path: "admin-dashboard",
          name: "Admin Dashboard 2.0",
          component: () => import("../views/Admin Dashboard"),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "admin-bills",
          name: "Invoices",
          component: () => import("../views/Admin Bills"),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "admin-petty-cash",
          name: "Petty Cash",
          component: () => import("../views/Admin Petty Cash"),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "admin-returns",
          name: "Returns",
          component: () => import("../views/Admin Returns"),
          meta: {
            requiresAuth: true
          }
        },

        // {
        //   path: "bill/:id",
        //   name: "Bill View",
        //   component: () => import("../views/Cart Bill"),

        // },

        {
          path: "admin-sales",
          name: "Sales Report",
          component: () => import("../views/Admin Sales"),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "admin-sales-product",
          name: "Products Sales Report",
          component: () => import("../views/Admin Sales Products"),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "admin-departments",
          name: "Departments",
          component: () => import("../views/Admin Departments"),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "customers",
          name: "Customers",
          component: () => import("../views/Customer"),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "customer-credit",
          name: "Customer Credit",
          component: () => import("../views/Customer Credit"),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "customer-credit/:id",
          name: "Customer Credit Detail",
          component: () => import("../views/Customer Credit Detail"),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "users",
          name: "Users",
          component: () => import("../views/Users"),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "roles",
          name: "Roles",
          component: () => import("../views/Roles"),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "admin-departments/:id",
          name: "Categories",
          component: () => import("../views/Admin Sub Departments"),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "admin-departments/admin-sub-departments/:id",
          name: "Products",
          component: () => import("../views/Admin Products"),
          meta: {
            requiresAuth: true
          }
        },

        {
          path: "admin-products/:id",
          name: "",
          component: () => import("../views/Product Info"),
          meta: {
            requiresAuth: true
          }
        },

        {
          path: "admin-products",
          name: "Products",
          component: () => import("../views/Admin View Products"),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "admin-product-prices",
          name: "Product Prices",
          component: () => import("../views/Admin Product Prices"),
          meta: {
            requiresAuth: true
          }
        },

        {
          path: "admin-products-prices/:id",
          name: "Product Price History",
          component: () => import("../views/Admin Product Price History"),
          meta: {
            requiresAuth: true
          }
        },

        {
          path: "admin-quick-menu",
          name: "Promotions",
          component: () => import("../views/Admin Quick Menu"),
          meta: {
            requiresAuth: true
          }
        },

        {
          path: "admin-units",
          name: "Units",
          component: () => import("../views/Admin Units"),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "admin-instructions",
          name: "Instructions",
          component: () => import("../views/Admin Instructions"),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "admin-sizes",
          name: "Sizes",
          component: () => import("../views/Admin Sizes"),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "admin-ingredients",
          name: "Ingredients",
          component: () => import("../views/Admin Ingredients"),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "admin-addons",
          name: "Add Ons",
          component: () => import("../views/Admin AddOns"),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "admin-addons/:id",
          name: "Add Ons Item",
          component: () => import("../views/Admin AddOns Item"),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "admin-options",
          name: "Options",
          component: () => import("../views/Admin Options"),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "admin-options/:id",
          name: "Options Item",
          component: () => import("../views/Admin Options Item"),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "admin-mealdeals",
          name: "Meal Deals",
          component: () => import("../views/Admin Meal Deals"),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "qr",
          name: "QR Code",
          component: () => import("../views/Admin Test"),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "admin-bills/:id",
          name: "Invoice Info",
          component: () => import("../views/OrderReview.vue"),
          meta: {
            requiresAuth: true
          }
        }
      ]
    },

    {
      path: "/",

      component: () => import("../layouts/default/Index Home"),

      children: [
        {
          path: "landing",
          name: "Landing Page",
          component: () => import("../views/Landing")
        },
        {
          path: "bill",
          name: "Bill",
          component: () => import("../views/Cart Bill")
        },
        {
          path: "bill/:id",
          name: "Bill View",
          component: () => import("../views/Cart Bill")
        },
        {
          path: "bill-html/:id",
          name: "Bill HTML",
          component: () => import("../views/Cart Bill HTML")
        }

        // {
        //   path: "kot",
        //   name: "KOT",
        //   component: () => import("../views/Kot"),
        //   meta: {
        //     requiresAuth: false
        //   }
        // }
      ],
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/",
      component: () => import("../layouts/default/Index"),
      children: [
        {
          path: "/home",
          name: "Promotions",
          component: () => import("../views/Home"),
          meta: {
            requiresAuth: false
          }
        },

        {
          path: "/cart/:id?",
          name: "Cart",
          component: () => import("../views/Cart"),
          meta: {
            requiresAuth: false
          }
        },

        {
          path: "/cart/bill/",
          name: "Bill",
          component: () => import("../views/Cart Bill"),
          meta: {
            requiresAuth: false
          }
        },
        {
          path: "/cart/bill/:id",
          name: "Bill",
          component: () => import("../views/Cart Bill Viewable"),
          meta: {
            requiresAuth: false
          }
        },

        {
          path: "/sub-department/:id",
          name: "Menu",
          component: () => import("../views/Products"),
          meta: {
            requiresAuth: false
          }
        }
      ]
    },

    {
      path: "/:pathMatch(.*)*",
      redirect: () => {
        return { path: "/home" };
      }
    }
  ]
});

router.beforeEach((to, from, next) => {
  var userRole = store.state.authUser.role;
  var accessRoles = to.matched[0].meta.access;

  // console.log(to.matched[0].meta.access);
  // console.log(store.state.authUser.role);
  // for(let i=0 ; i< to.matched.length ; i++){
  //   console.log(to.matched[0].meta.access);
  // }

  //  console.log(to.matched.some(record => record.meta.access));

  //newly added - with license expired
  //if(to.fullPath === "/home"){
  //  next("/licenseExpired");
 // }
  //

  if (from.path !== to.path || from.name !== to.name) {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    store
      .dispatch("authUser/authenticate")
      .then(() => {
        if (accessRoles.includes(userRole)) {
          //newly added - with license expired
        //  next("/licenseExpired");

          //old - without license expired
          next()

        } else {
          //console.log(from.path)
          alert("Access Denied");
          next(from.path);
        }
      })

      .catch(() => {
        next("/login");
      });
     
  } else {
    next();
  }
  }
  
  
});

export default router;
