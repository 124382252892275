// Vue
import Vue from "vue";
import Vuex from "vuex";
import pathify from "@/plugins/vuex-pathify";

// Modules
import * as modules from "./modules";


Vue.use(Vuex);

const store = new Vuex.Store({
  modules,
  plugins: [pathify.plugin],
  state: {
    menuSubList: [],
    menuName: "",
    cartData: [],
    cartNetCost: 0,
    cartGst: 0,
    orderType: 1,
    cartBillNo: 0,
    storeBillInfo: "",
    checkoutLogin: false
  },
  mutations: {
    ADD_CHECKOUT_LOGIN: (state, data) => {
      state.checkoutLogin = data;
    },
    ADD_MENU_LIST: (state, data) => {
      state.menuSubList.push(data);
    },
    ADD_MENU_NAME: (state, data) => {
      state.menuName = data;
    },
    ADD_ORDER_TYPE: (state, data) => {
      state.orderType = data;
      console.log("ADD_ORDER_TYPE state.orderType", state.orderType);
      window.localStorage.setItem("orderTypeStore", state.orderType);
      console.log(
        "Local - store",
        window.localStorage.getItem("orderTypeStore")
      );
    },
    UPDATE_CART_DATA: (state, data) => {
      state.cartData = data;
      window.localStorage.setItem(
        "cartDataStore",
        JSON.stringify(state.cartData)
      );
    },
    ADD_CART_DATA: (state, data) => {
      state.cartData.push(data);
      window.localStorage.setItem(
        "cartDataStore",
        JSON.stringify(state.cartData)
      );
      //  this.commit('saveCart')

      // var objIndex = state.cartData.findIndex(
      //   obj => obj.itemName == data.itemName
      // );

      // if (objIndex >= 0) {
      //   //Update object's name property.
      //   state.cartData[objIndex].quantity =
      //     state.cartData[objIndex].quantity + data.quantity;
      //   state.cartData[objIndex].cost =
      //     state.cartData[objIndex].cost + data.cost;

      //   //Log object to console again.
      // } else {
      //   state.cartData.push(data);
      // }
    },

    ADD_NET_COST: (state, data) => {
      state.cartNetCost = state.cartNetCost + data;
      state.cartGst = (state.cartNetCost * 10) / 100;
      window.localStorage.setItem("cartGstStore", state.cartGst);
      window.localStorage.setItem("cartNetCostStore", state.cartNetCost);
    },
    UPDATE_NET_COST: (state, data) => {
      state.cartNetCost = data;
      state.cartGst = (state.cartNetCost * 10) / 100;
      console.log(state.cartGst);
      window.localStorage.setItem("cartGstStore", state.cartGst);
      window.localStorage.setItem("cartNetCostStore", state.cartNetCost);
    },
    ADD_BILL_NO: (state, data) => {
      state.cartBillNo = data;
    },
    ADD_BILL_INFO: (state, data) => {
      state.storeBillInfo = data;
      window.localStorage.setItem(
        "localBillInfo",
        JSON.stringify(state.storeBillInfo)
      );

      console.log("ADD Bill", data);
      console.log("local Bill", window.localStorage.getItem("localBillInfo"));
    }
  },
  getters: {
    getSubDepartment: state => state.menuName,
  }
});

store.subscribe(mutation => {
  if (!mutation.type.startsWith("user/")) return;

  store.dispatch("user/update", mutation);
});

store.dispatch("app/init");

export default store;

export const ROOT_DISPATCH = Object.freeze({ root: true });
