import moment from "moment";
export default {
    install(Vue) {
        const hub = new Vue();
        var port = null;
        var status = false;
        const webSerialApi = {
            hub, status,
            getStatus() {
                return status
            },
            async startConnection() {
                try {
                    port = await navigator.serial.requestPort();
                    await port.open({ baudRate: 19200, databits: 8, stopbits: 1, parity: "none", flowControl: "none" });
                    status = true;
                    hub.$emit('status', true);
                } catch (e) {
                    status = false;
                    hub.$emit('status', false);
                    console.log("WebSerialApi: ", e);
                }
            },

            template(bill, company) {
                var newLine = '\x0A';
                var esc = '\x1B';
                var cmds = esc + "@"; //Initializes the printer (ESC @)
                cmds += esc + 'a' + '\x01';
                cmds += esc + '!' + '\x28';
                // cmds += esc + '!' + '\x08'; //Emphasized + Double-height + Double-width mode selected (ESC ! (8 + 16 + 32)) 56 dec => 38 hex
                cmds += esc + '!' + '\x18';
                cmds += company.companyName; //text to print
                cmds += newLine;
                cmds += esc + '!' + '\x08';
                cmds += `ABN ${company.abn}`; //text to print
                cmds += newLine;
                cmds += esc + '!' + '\x08';
                cmds += company.address.replace('<br/>', (newLine + esc + '!' + '\x08')); //text to print
                cmds += newLine;
                cmds += esc + '!' + '\x08';
                cmds += `Phone ${company.phoneNumber}`; //text to print
                cmds += newLine;
                cmds += esc + '!' + '\x08';
                cmds += `Date ${this.dateFormat(bill.createdAt)}`; //text to print
                cmds += newLine;
                cmds += esc + '!' + '\x08';
                cmds += `Bill Number ${bill.billNo}`; //text to print
                cmds += newLine + newLine;
                cmds += esc + '!' + '\x08';
                cmds += esc + '!' + '\x00'; //Character font A selected (ESC ! 0)
                cmds += esc + 'a' + '\x00';
                cmds += esc + '\x5C' + '\x24' + '\x00';

                if (bill && bill.billProducts) {
                    bill.billProducts.forEach(product => {
                        cmds += this.getDetail(product);
                        cmds += newLine;
                        cmds += esc + '\x5C' + '\x24' + '\x00';
                    })
                }

                cmds += newLine;
                cmds += esc + '\x5C' + '\x24' + '\x00';
                cmds += 'SUBTOTAL'.padEnd(25, ' ') + ('$' + this.currencyFormat(this.subTotal(bill))).padStart(8, ' ');

                if (bill.extraCharges) {
                    cmds += newLine;
                    cmds += esc + '\x5C' + '\x24' + '\x00';
                    cmds += 'EXTRAS'.padEnd(25, ' ') + ('$' + this.currencyFormat(bill.extraCharges)).padStart(8, ' ');
                }

                if (bill.deliveryCharge) {
                    cmds += newLine;
                    cmds += esc + '\x5C' + '\x24' + '\x00';
                    cmds += 'DELIVERY'.padEnd(25, ' ') + ('$' + this.currencyFormat(bill.deliveryCharge)).padStart(8, ' ');
                }

                if (bill.discountTotal) {
                    cmds += newLine;
                    cmds += esc + '\x5C' + '\x24' + '\x00';
                    cmds += 'DISCOUNT'.padEnd(25, ' ') + ('$' + this.currencyFormat(bill.discountTotal)).padStart(8, ' ');
                }

                cmds += newLine;
                cmds += esc + '\x5C' + '\x24' + '\x00';
                cmds += esc + '!' + '\x08';
                cmds += 'TOTAL'.padEnd(25, ' ') + ('$' + this.currencyFormat(this.total(bill))).padStart(8, ' ');
                cmds += newLine;
                cmds += esc + '\x5C' + '\x24' + '\x00';
                cmds += esc + '!' + '\x00';
                cmds += 'CASH'.padEnd(25, ' ') + ('$' + this.currencyFormat(bill.tendered)).padStart(8, ' ');
                cmds += newLine;
                cmds += esc + '\x5C' + '\x24' + '\x00';
                cmds += 'BALANCE'.padEnd(25, ' ') + ('$' + this.currencyFormat(this.balance(bill))).padStart(8, ' ');
                cmds += newLine + newLine;
                cmds += esc + '!' + '\x08';
                cmds += esc + 'a' + '\x01';
                cmds += 'THANK YOU'; //text to print
                cmds += newLine;
                cmds += esc + 'a' + '\x01';
                cmds += esc + '!' + '\x00';
                cmds += 'TRADING HOURS'; //text to print
                cmds += newLine;
                cmds += esc + '!' + '\x08';
                cmds += 'MON-FRI 8:30 - 5.30'; //text to print
                cmds += newLine;
                cmds += esc + '!' + '\x08';
                cmds += 'SATURDAY 8:30 - 4.00'; //text to print
                cmds += newLine;
                cmds += esc + '!' + '\x08';
                cmds += 'SUNDAY CLOSED'; //text to print
                cmds += newLine;
                cmds += esc + '!' + '\x08';
                cmds += bill.custCode ? "CLERK " + bill.custCode : ""; //text to print
                cmds += newLine;
                cmds += newLine + newLine;
                cmds += newLine + newLine;
                cmds += newLine + newLine;
                return cmds;
            },

            print(bill, company) {
                console.log("WebSerialApi: ", "print");
                return new Promise(async (resolve, reject) => {
                    try {
                        let content = this.template(bill, company);
                        const encoder = new TextEncoder()
                        const encodedValue = encoder.encode(content)
                        console.log(content, encodedValue);
                        const writer = port.writable.getWriter();
                        await writer.write(encodedValue);
                        setTimeout(async () => {
                            var CUT_COMMAND = '\x1B' + '@' + '\x1D' + 'V' + '\x30' //cut;
                            const encodedCutCommand = encoder.encode(CUT_COMMAND)
                            await writer.write(encodedCutCommand);
                            setTimeout(() => {
                                //var CASHDKICK = '\x1B' + 'p' + '\x00' + '\x19' + '\xFA'; // Cash drawer kick ESC + p + 0 + 25 + 250
                                //serialPort.write(CASHDKICK);
                                //const encodedCASHDKICKCommand = encoder.encode(CASHDKICK)
                                //writer.write(encodedCASHDKICKCommand);
                                writer.releaseLock();
                                resolve(true);
                            }, 100);
                        }, 150)
                    } catch (error) {
                        console.log(error);
                        resolve(false);
                    }
                })
            },
            cashDrawerKick() {
                console.log("WebSerialApi: ", "cashDrawerKick");
                return new Promise(async (resolve, reject) => {
                    try {
                        const encoder = new TextEncoder();
                        const writer = port.writable.getWriter();
                        const CASH_D_KICK = '\x1B' + 'p' + '\x00' + '\x19' + '\xFA'; // Cash drawer kick ESC + p + 0 + 25 + 250
                        const encodedCASH_D_KICK = encoder.encode(CASH_D_KICK)
                        await writer.write(encodedCASH_D_KICK);
                        setTimeout(async () => {
                            writer.releaseLock();
                            resolve(true);
                        }, 150)
                    } catch (error) {
                        console.log(error);
                        resolve(false);
                    }
                })
            },
            getDetail(product) {
                let qty = (product.unitQty && product.unitQty > 0
                    ? product.quantity / product.unitQty
                    : product.quantity);
                let unit = product.unitName;
                let desc = product.productDescription
                let price = '$' + this.currencyFormat(product.total);
                let detail = qty + unit + ' ' + desc;
                detail = detail.trim();
                if (detail.length > 25) {
                    detail = detail.substring(0, 25);
                } else {
                    detail = detail.padEnd(25, ' ');
                }
                if (price.length < 8) {
                    price = price.padStart(8, ' ');
                }
                return detail + price;
            },
            subTotal(bill) {
                let amount = 0;
                if (bill && bill.billProducts) {
                    amount = bill.billProducts.reduce((total, x) => {
                        total += x.total;
                        return total;
                    }, 0);
                }
                return amount;
            },
            total(bill) {
                return bill.billTotal;
            },
            balance(bill) {
                return bill.tendered - bill.billTotal;
            },
            dateFormat(val) {
                return moment.utc(val).local().format("DD/MM/YYYY h:mm:ss a");
            },
            currencyFormat(val) {
                return val ? parseFloat(val).toFixed(2) : 0.0;
            }
        }

        Vue.prototype.$webSerialPrinterApi = webSerialApi;
    },
}
