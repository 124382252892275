// Pathify
import { make } from "vuex-pathify";

// Data
const state = {
  drawer: null,
  drawerImage: true,
  mini: true,

  tabs: [
    // {
    //   title: "Dashboard",
    //   icon: "mdi-chart-bar",
    //   to: "/admin/admin-bills"
    // },
    {
      title: "Invoices",
      icon: "mdi-receipt",
      to: "/admin/admin-bills"
    },
    {
      title: "Petty Cash",
      icon: "mdi-account-cash",
      to: "/admin/admin-petty-cash"
    },
    {
      title: "Returns",
      icon: "mdi-cash-refund ",
      to: "/admin/admin-returns"
    },
    {
      title: "Departments",
      icon: "mdi-lan",
      to: "/admin/admin-departments"
    },

    {
      title: "Products",
      icon: "mdi-food",
      to: "/admin/admin-products"
    },
    {
      title: "Product Prices",
      icon: "mdi-currency-usd",
      to: "/admin/admin-product-prices"
    },



    {
      title: "Customers",
      icon: "mdi-account-tie",
      to: "/admin/customers"
    },
    {
      title: "Customer Credit",
      icon: "mdi-account-credit-card",
      to: "/admin/customer-credit"
    },
    {
      title: "Users",
      icon: "mdi-account-group",
      to: "/admin/users"
    },
    {
      title: "Sales Report",
      icon: "mdi-clipboard",
      to: "/admin/admin-sales"
    },
    {
      title: "Products Sales Report",
      icon: "mdi-clipboard-file",
      to: "/admin/admin-sales-product"
    },
    {
      title: "Units",
      icon: "mdi-scale",
      to: "/admin/admin-units"
    },
    // {
    //   title:"Sub Departments",
    //   icon:"mdi-package",
    //   to: "/admin/admin-sub-departments"
    // },

    // {
    //   title: "Promotions",
    //   icon: "mdi-sale",
    //   to: "/admin/admin-quick-menu"
    // },

    // {
    //   title: "Sizes",
    //   icon: "mdi-shape-circle-plus",
    //   to: "/admin/admin-sizes"
    // },
    // {
    //   title: "Ingredients",
    //   icon: "mdi-food-variant",
    //   to: "/admin/admin-ingredients"
    // },
    // {
    //   title: "Add Ons",
    //   icon: "mdi-cheese",
    //   to: "/admin/admin-addons"
    // },
    // {
    //   title: "Options",
    //   icon: "mdi-chili-mild",
    //   to: "/admin/admin-options"
    // },
    // {
    //   title: "Instructions",
    //   icon: "mdi-comment",
    //   to: "/admin/admin-instructions"
    // },
    // {
    //   title: "Meal Deals",
    //   icon: "mdi-rice",
    //   to: "/admin/admin-mealdeals"
    // },
    // {
    //   title: "QR",
    //   icon: "mdi-qrcode",
    //   to: "/admin/qr"
    // }
  ],

  // items: [
  //   {
  //     title: "Home",
  //     icon: "mdi-food",
  //     to: "/home"
  //   },

  //   {
  //     title: "Snacks",
  //     to: "/sub-department/4",
  //     image: require("@/assets/sub departments/snacks.jpg")
  //   },
  //   {
  //     title: "Kothu",
  //     to: "/sub-department/5",
  //     image: require("@/assets/sub departments/kothu.jpg")
  //   },
  //   {
  //     title: "Roti",
  //     to: "/sub-department/6",
  //     image: require("@/assets/sub departments/roti.jpg")
  //   },
  //   {
  //     title: "Naan",
  //     to: "/sub-department/7",
  //     image: require("@/assets/sub departments/naan.jpg")
  //   },
  //   {
  //     title: "Goreng",
  //     to: "/sub-department/9",
  //     image: require("@/assets/sub departments/goreng.jpg")
  //   },
  //   {
  //     title: "Fried Rice",
  //     to: "/sub-department/10",
  //     image: require("@/assets/sub departments/fried rice.jpg")
  //   },
  //   {
  //     title: "Hopper",
  //     to: "/sub-department/11",
  //     image: require("@/assets/sub departments/hopper.jpg")
  //   },
  //   {
  //     title: "Briyani",
  //     to: "/sub-department/12",
  //     image: require("@/assets/sub departments/briyani.jpg")
  //   },

  //   {
  //     title: "Veg Curries",
  //     to: "/sub-department/13",
  //     image: require("@/assets/sub departments/veg curry.jpg")
  //   },
  //   {
  //     title: "No-Veg Curries",
  //     to: "/sub-department/14",
  //     image: require("@/assets/sub departments/non veg curry.jpg")
  //   },
  //   {
  //     title: "Masala",
  //     to: "/sub-department/15",
  //     image: require("@/assets/sub departments/masala.jpg")
  //   },
  //   {
  //     title: "Chicken",
  //     to: "/sub-department/16",
  //     image: require("@/assets/sub departments/chicken.jpg")
  //   },
  //   {
  //     title: "Dosa",
  //     to: "/sub-department/17",
  //     image: require("@/assets/sub departments/dosa.jpg")
  //   },
  //   {
  //     title: "Puttu",
  //     to: "/sub-department/18",
  //     image: require("@/assets/sub departments/puttu.jpg")
  //   },
  //   {
  //     title: "Meals",
  //     to: "/sub-department/19",
  //     image: require("@/assets/sub departments/meals.jpg")
  //   },
  //   {
  //     title: "Hot Drinks",
  //     to: "/sub-department/20",
  //     image: require("@/assets/sub departments/hot drink.jpg")
  //   },
  //   {
  //     title: "Cold Drinks",
  //     to: "/sub-department/21",
  //     image: require("@/assets/sub departments/cold drink.jpg")
  //   }
  // ]
};

const mutations = make.mutations(state);

const actions = {
  ...make.actions(state),
  init: async ({ dispatch }) => {
    //
  }
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  data() {
    return {
      subDepartments: []
    };
  },

};
