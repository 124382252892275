export default {
    install(Vue) {
        const hub = new Vue();
        var port = null;
        var status = false;
        const webSerialApi = {
            hub, status,
            getStatus() {
                return status
            },
            async startConnection() {
                try {
                    port = await navigator.serial.requestPort();
                    await port.open({ baudRate: 9600, databits: 8, stopbits: 1, parity: "none", flowControl: "none" });
                    status = true;
                    hub.$emit('status', true);

                    const reader = port.readable.getReader();
                    var result = "";
                    while (true) {
                        const { value, done } = await reader.read();
                        if (done) {
                            reader.releaseLock();
                            break;
                        }
                        let decodedVal = new TextDecoder("utf-8").decode(value);
                        result = result + decodedVal;
                        if (decodedVal && decodedVal.includes("kg")) {
                            result = result.substring(6);
                            result = result.substring(0, result.length - 5)
                           // result = result.replace(/\D/g, '');
                           result = result.replace(/[^\d.-]/g, '');
                            //result = /^(\d+)?(?:\.\d{1,3})?$/;
                            console.log("WebSerialApi: ", result);
                            hub.$emit('get-weight', result);
                            result = "";
                        }
                    }
                } catch (e) {
                    status = false;
                    hub.$emit('status', false);
                    console.log("WebSerialApi: ", e);
                }
            },

            getWeight() {
                console.log("WebSerialApi: ", "getWeight");
                return new Promise(async (resolve, reject) => {
                    try {
                        const writer = port.writable.getWriter();
                        await writer.write(new Uint8Array([5]));
                        setTimeout(async () => {
                            await writer.write(new Uint8Array([17]));
                            writer.releaseLock();
                            resolve(true);
                        }, 300)
                    } catch (error) {
                        resolve(false);
                    }
                })
            }
        }

        Vue.prototype.$webSerialApi = webSerialApi;
    },
}
