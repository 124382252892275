<template>
  <div>
    <v-card-text>
      <v-container>
        <v-col cols="12" sm="12" md="12">
          <v-text-field
            v-model="textInput"
            label="Input Text"
            outlined
            clearable
          />

          <qr-code size="100" :text="textInput" v-if="textInput"></qr-code>
        </v-col>
      </v-container>
    </v-card-text>
  </div>
</template>
<script>
import Vue from "vue";
import VueQRCodeComponent from "vue-qrcode-component";
Vue.component("qr-code", VueQRCodeComponent);

export default {
  name: "QrCodeComponent",
  props: {
    textInput: String
  }
 
};
</script>
